import { Link, graphql } from "gatsby"
import { Organization, allCategoriesType } from "../utils/model"
import React, { useEffect, useMemo } from "react"
import { sortBy, sortIds } from "../utils/commonUtils"

import Layout from "../components/layout"
import OrgLine from "../components/OrgLine"
import SEO from "../components/seo"
import queryString from "query-string"
import { useCategoryTree } from "../utils/custom-hooks"

type CategoryTemplateProps = {
  data: {
    categories: allCategoriesType
    category
    organizationsByName
    organizationsByParentName
    organizationsBy2ndParentName
    organizationsBy3rdParentName
    organizationsBy4thParentName
  }
  pageContext: {
    categoryId
    airtableId: string
    categoryName: string
  }
}

function CategoryTemplate(props: CategoryTemplateProps) {
  console.log(
    "categoryId :" +
      props.pageContext.categoryId +
      "airtableId" +
      props.pageContext.airtableId +
      "categoryName" +
      props.pageContext.categoryName
  )

  let categories = useCategoryTree()

  const category = categories.find(props.pageContext.categoryId)

  category.desc =
    props.data?.category.data.Description?.childMarkdownRemark.html
  category.SEOTitle = props.data?.category.data.SEOTitle
  category.SEODesc = props.data?.category.data.SEODesc
  category.keyFigures =
    props.data?.category.data.Highlights?.childMarkdownRemark.html
  category.contributionType = props.data?.category.data.contributionType

  let cats = category.ancestors()
  cats.reverse().shift()

  const {
    organizationsBy4thParentName,
    organizationsBy3rdParentName,
    organizationsBy2ndParentName,
    organizationsByParentName,
    organizationsByName,
  } = props.data

  // Merge the results from different queries and sort them by DateValidated
  const categoryOrganizations = [
    ...organizationsBy4thParentName?.edges,
    ...organizationsBy3rdParentName?.edges,
    ...organizationsBy2ndParentName?.edges,
    ...organizationsByParentName?.edges,
    ...organizationsByName?.edges,
  ].reduce((organizations, current) => {
    // remove duplicated organizations
    if (!organizations.find((org) => org.node.data.Name === current.node.data.Name)) {
      organizations.push(current);
    }
    return organizations;
  }, []).map(org => { return { id: org.node.id, ...org.node.data }}).sort(sortBy("DateValidated","desc"));
  
  const lastOrganizationsAdded: Organization[] = categoryOrganizations.slice(0,3).map(org => {
    return new Organization({
      id: org.id,
      name: org.Name,
      desc: org.ActivityDescription.childMarkdownRemark.html,
      tags: org.Tags.map((tag) => { return tag.data.Tag}),
      structure: org.StructureType,
      effectif: org.Workforce,
      categories: org.Categories,
      creationYear: parseInt(org.CreationYear),
      headquarter: org.Headquarter[0].data.CityName
    })
  })

  return (
    <Layout contentClassName="bg-gray-100">
      <SEO
        title={
          `${category.SEOTitle}` ||
          `${category.originalName} : les organisations sur CartoJob`
        }
        description={`${category.SEODesc}`}
        gtmPageType="secteur"
        gtmPageName={`secteur-${category.name}`}
      />

      <div className="overflow-x-hidden">
        <div className="px-2 flex flex-col container max-w-3xl mx-2 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
          <p className="font-sans text-xs text-gray-700 mt-8 uppercase tracking-widest lg:max-w-lg ">
            Secteur
          </p>
          <h1 className="text-3xl xl:text-5xl leading-none text-primary font-black mb-3">
            {category.originalName}
          </h1>

          {category.children.length > 0 && (
                      <Link
                      to={
                        category.searchSlug
                          ? category.searchSlug
                          : "/search/?" +
                            queryString.stringify(category.filters().filters)
                      }
                      rel={category.searchSlug ? "dofollow" : "nofollow"}
                      className="mt-4 font-sans font-bold whitespace-no-wrap w-fit bg-secondary p-2 text-primary text-center rounded px-8 py-4 transition duration-300 ease hover:bg-primary hover:text-secondary"
                    >
              Voir les {category.newOrgCount} organisations
            </Link>
          )}

          {category.level > 1 && (
            <div className="flex flex-wrap justify-start text-2xs lg:text-xs pr-1 mb-4">
              <div className="flex items-center">
                <div className="mr-3 font-sans ">dans</div>
                {cats.map(ancestor => (
                  <div className="breadcrumb-item flex items-center ">
                    <Link
                      key={ancestor.id}
                      to={ancestor.slug}
                      className="text-primary text-xs font-sans bg-white flex-initial rounded border border-gray-200 px-2 py-1 no-underline transition duration-300 ease hover:text-white hover:bg-primary"
                    >
                      {ancestor.originalName}
                    </Link>
                    <span className="sep leading-3 mx-1">❭</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="mx-2 pt-6 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl gap-y-2">
          <div className="ml-16 pt-20 max-w-md hidden lg:block float-right">
            {/* Category description */}
            {category.keyFigures && (
              <div className="bg-shift text-white relative mb-2 p-10 pt-20 pr-8 md:pr-0 max-w-md hidden lg:block float-right">
                <img
                  className="absolute bg-white rounded-md shadow-xl p-6"
                  style={{
                    width: "130px",
                    height: "130px",
                    top: "-70px",
                    left: "35px",
                  }}
                  src={category.pictoURL}
                />
                <div
                  className="text-lg font-sans"
                  dangerouslySetInnerHTML={{ __html: category.keyFigures }}
                />
                <div
                  className="absolute h-full bg-shift left-auto z-0 top-0 right-0 transform translate-x-full"
                  style={{ width: "calc(100%)" }}
                ></div>
              </div>
            )}
            {/* Category sous-secteurs */}
            {category.children.length > 0 && (
              <div
                id="sous-secteurs"
                className="bg-primary text-white relative p-10 pr-8 md:pr-0 max-w-md hidden lg:block float-right"
              >
                <h2 className="text-lg text-secondary font-bold">
                  Les sous-secteurs dans “
                  <span className="capitalize-first-letter">
                    {category.name}
                  </span>
                  ”
                </h2>
                <ul className="flex justify-left flex-wrap 2xl:mx-10">
                  {category.children.sort(sortIds("id")).map(cat => (
                    <li key={cat.id} className="font-sans mx-2">
                      <Link
                        to={
                          cat.hasDedicatedPage
                            ? cat.slug
                            : cat.searchSlug
                            ? cat.searchSlug
                            : "/search/?" +
                              queryString.stringify(cat.filters().filters)
                        }
                        rel={
                          cat.hasDedicatedPage || cat.searchSlug
                            ? "dofollow"
                            : "nofollow"
                        }
                      >
                        <div className="no-underline inline-block leading-snug text-white font-bold rounded border border-gray-600 px-4 py-2 mt-4  capitalize-first-letter transition duration-300 ease hover:border-white flex items-center">
                          <span className="text-sm">{cat.name}</span>
                          <span className="bg-gray-600 h-4 w-px top-0 left-0 mx-2"></span>
                          <span className="text-sm">{cat.newOrgCount}</span>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
                <div
                  className="absolute h-full bg-primary left-auto z-0 top-0 right-0 transform translate-x-full"
                  style={{ width: "calc(100%)" }}
                ></div>
              </div>
            )}
          </div>
          <div className="px-2 container max-w-3xl">
            {/* Category description */}
            {category.keyFigures && (
              <div className="bg-shift text-white relative p-10 mt-20 mb-1 lg:hidden">
                <img
                  className="absolute bg-white rounded-md shadow-xl p-3"
                  style={{
                    maxWidth: "100px",
                    maxHeight: "100px",
                    top: "-4em",
                    left: "1em",
                  }}
                  src={category.pictoURL}
                />
                <div
                  className="text-lg font-sans font-bold "
                  dangerouslySetInnerHTML={{ __html: category.keyFigures }}
                />
              </div>
            )}
            {/* Category sous-secteurs */}
            {category.children.length > 0 && (
              <div
                id="sous-secteurs"
                className="bg-primary text-white relative p-10 mb-8 lg:hidden"
              >
                <h2 className="text-lg text-secondary font-bold">
                  Les sous-secteurs dans “
                  <span className="capitalize-first-letter">
                    {category.name}
                  </span>
                  ”
                </h2>
                <ul className="flex justify-left flex-wrap 2xl:mx-10">
                  {category.children.sort(sortIds("id")).map(cat => (
                    <li key={cat.id} className="font-sans mx-2">
                      <Link
                        to={
                          cat.hasDedicatedPage
                            ? cat.slug
                            : cat.searchSlug
                            ? cat.searchSlug
                            : "/search/?" +
                              queryString.stringify(cat.filters().filters)
                        }
                        rel={
                          cat.hasDedicatedPage || cat.searchSlug
                            ? "dofollow"
                            : "nofollow"
                        }
                      >
                        <div className="no-underline inline-block leading-snug text-white font-bold rounded border border-gray-600 px-4 py-2 mt-4  capitalize-first-letter transition duration-300 ease hover:border-white flex items-center">
                          <span className="text-sm">{cat.name}</span>
                          <span className="bg-gray-600 h-4 w-px top-0 left-0 mx-2"></span>
                          <span className="text-sm">{cat.newOrgCount}</span>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div
              className="markdown"
              dangerouslySetInnerHTML={{
                __html: category.desc,
              }}
            />
          </div>
        </div>
      </div>

      <div className="px-2 container flex flex-col items-start mx-2 max-w-3xl md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
        <h2 className="mt-12 mb-[1.5] text-shift font-bold text-[1.625rem]">
          Dernières organisations ajoutées dans {category.name}
        </h2>
        <div className="mt-8">
          {lastOrganizationsAdded.map(org => (
            <OrgLine key={org.id} organization={org} />
          ))}
        </div>
        <div className="flex justify-center w-full">
          <Link
              to={
                category.searchSlug
                  ? category.searchSlug
                  : "/search/?" +
                    queryString.stringify(category.filters().filters)
              }
              rel={category.searchSlug ? "dofollow" : "nofollow"}
              className="font-sans font-bold whitespace-no-wrap w-auto bg-secondary p-2 text-primary text-center rounded px-8 py-4 transition duration-300 ease hover:bg-primary hover:text-secondary"
            >
              Toutes les organisations de ce secteur
            </Link>
          </div>
      </div>

      <div className="px-2 pt-6 pb-16">
        <div className="container mx-2 max-w-3xl md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
          <small className="block mt-8 font-sans font-bold font-sm mb-8">
            Dans l’esprit collaboratif qui a été au cœur de ce projet, vous
            pouvez suggérer des ajouts ou des modifications. Ces suggestions
            seront soumises au même processus de validation qu’à la création de
            la base de données.
          </small>
          <Link
            to="/contribuer"
            className="inline-flex no-underline font-sans font-bold text-sm bg-transparent rounded border-2 border-secondary text-primary text-center px-6 py-3 transition duration-300 ease transition-colors hover:bg-secondary hover:text-primary "
          >
            Suggérer une modification
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CategoryPageQuery($airtableId: String, $categoryName: String) {
    category: airtable(id: { eq: $airtableId }) {
      id
      data {
        Order
        Name
        SEOTitle
        SEODesc
        Description {
          childMarkdownRemark {
            html
          }
        }
        Highlights {
          childMarkdownRemark {
            html
          }
        }
        CategoryPictoURL
        CategoryPicto {
          localFiles {
            url
          }
        }
        ContributionType
        OrgCount
        HasDedicatedPage
      }
    }

    organizationsByName: allAirtable(
      filter: {
        table: { eq: "Organization" }
        data: {
          Categories: { elemMatch: { data: { Name: { in: [$categoryName] } } } }
        }
      }
      sort: { order: DESC, fields: data___DateValidated }
      limit: 3
    ) {
      edges {
        node {
          id
          data {
            DateValidated
            Name
            ActivityDescription {
              childMarkdownRemark {
                html
              }
            }
            StructureType
            CreationYear
            Workforce
            Headquarter {
              id
              data {
                CityName
              }
            }
            Tags {
              id
              data {
                Tag
              }
            }
          }
        }
      }
    }

    organizationsByParentName: allAirtable(
      filter: {
        table: { eq: "Organization" }
        data: {
          Categories: {
            elemMatch: { data: { ParentName: { in: [$categoryName] } } }
          }
        }
      }
      sort: { order: DESC, fields: data___DateValidated }
      limit: 3
    ) {
      edges {
        node {
          id
          data {
            DateValidated
            Name
            ActivityDescription {
              childMarkdownRemark {
                html
              }
            }
            StructureType
            CreationYear
            Workforce
            Headquarter {
              id
              data {
                CityName
              }
            }
            Tags {
              id
              data {
                Tag
              }
            }
          }
        }
      }
    }

    organizationsBy2ndParentName: allAirtable(
      filter: {
        table: { eq: "Organization" }
        data: {
          Categories: {
            elemMatch: { data: { _2ndParentName: { in: [$categoryName] } } }
          }
        }
      }
      sort: { order: DESC, fields: data___DateValidated }
      limit: 3
    ) {
      edges {
        node {
          id
          data {
            DateValidated
            Name
            ActivityDescription {
              childMarkdownRemark {
                html
              }
            }
            StructureType
            CreationYear
            Workforce
            Headquarter {
              id
              data {
                CityName
              }
            }
            Tags {
              id
              data {
                Tag
              }
            }
          }
        }
      }
    }

    organizationsBy3rdParentName: allAirtable(
      filter: {
        table: { eq: "Organization" }
        data: {
          Categories: {
            elemMatch: { data: { _3rdParentName: { in: [$categoryName] } } }
          }
        }
      }
      sort: { order: DESC, fields: data___DateValidated }
      limit: 3
    ) {
      edges {
        node {
          id
          data {
            DateValidated
            Name
            ActivityDescription {
              childMarkdownRemark {
                html
              }
            }
            StructureType
            CreationYear
            Workforce
            Headquarter {
              id
              data {
                CityName
              }
            }
            Tags {
              id
              data {
                Tag
              }
            }
          }
        }
      }
    }

    organizationsBy4thParentName: allAirtable(
      filter: {
        table: { eq: "Organization" }
        data: {
          Categories: {
            elemMatch: { data: { _4thParentName: { in: [$categoryName] } } }
          }
        }
      }
      sort: { order: DESC, fields: data___DateValidated }
      limit: 3
    ) {
      edges {
        node {
          id
          data {
            DateValidated
            Name
            ActivityDescription {
              childMarkdownRemark {
                html
              }
            }
            StructureType
            CreationYear
            Workforce
            Headquarter {
              id
              data {
                CityName
              }
            }
            Tags {
              id
              data {
                Tag
              }
            }
          }
        }
      }
    }
  }
`

export default CategoryTemplate
